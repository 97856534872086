.modal{
  height: 400px;
  position: relative;
}
.awardModal{
  width: 100%;
  height: 100%;
  max-width: 600px;
}
.theme-rw{
  font-size:12px;
  margin-bottom: 4px;
  margin-top:2px;
  color:silver
}
.modal-award-content {
  position: absolute;
  width: 100%;
  text-align: center;
}

.modal-award-content-top{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -270px;
  margin-bottom: 32px;
}
.theme{
  margin-bottom: 8px;
}
.modal-award-content-bottom{
  display: flex;
  justify-content: center;
  width: 100%;
}
.modal-award-content-bottom2{
  width: 100%;
  bottom: 20px;
}
.row{
  bottom: 20px;;
}
.close-btn{
  position: absolute;
  left:50%;
  bottom: -30px;;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: row;
}
.best-score{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.main-score{
  text-align: center;
  font-size: 30px;
  color: yellow;
  letter-spacing: 3px;
  width: 250px;
  margin-bottom: 5px;

}
.sub-score{
  display: flex;
  gap: 0 9px;
}

.title-sub-score{
  color: burlywood;
}

.line{
  color: purple;
  font-family: 'Courier New', Courier, monospace;
}

.title-detailScore{
  color: #b47c5486;
}

@media screen and (max-width: 768px) { 
  .close-btn{
    position: absolute;
    left:50%;
    bottom: -142px;;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: row;
  }
  /* .awardModal{
    margin-top: 135px;
    width: 700px;
    height: 400px;
  } */
  .btn-claim{
    display: flex;
    justify-content: center;
    width: 70px !important;
    padding: 5px 10px !important;
    margin-top: 420px !important;
    margin-left: -31px !important;
  }
  .btn-claim{
    width: 76px !important;
    height: 27px !important;
  }
  .modal-content{
    font-size: 12px;
    margin-top: 262px;
    
  }
  .main-score{
    font-size: 20px;
    margin-bottom: 10px;
    width: 200px;
  }
}
.btn-claim {
  position: absolute;
  width: 100px;
  height: 35px;
  border: none;
  outline: none;
  color: #fff;
  background-color: #8834cc;
  cursor: pointer;
  z-index: 0;
  border-radius: 10px;
  /* margin-top: 323px;
  margin-left: -55px; */
}

.btn-claim:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.btn-claim:active {
  color: black
}

.btn-claim:active:after {
  background: transparent;
}

.btn-claim:hover:before {
  opacity: 1;
}

.btn-claim:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #8834cc;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}