.containModal{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 88;
    overflow: auto;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.4);
}

.broad-approve{
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 300px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.broad-approve-top{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.confirm-img{
    width: 50px;
    height: 50px;
    object-fit: contain;
    background-color: transparent;
    padding-bottom: 10px;
}

.approve-content{
    font-family: "Jersey M54";
    color: black;
}

.broad-approve-bottom{
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.broad-approve-btn{
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 6px;
    font-family: "Jersey M54";
    text-align: center;
}

.btn--approve{
    background-color: green;
}

.btn--cancle{
    background-color: red;
}

.btn--approve:hover{
    background-color: rgba(59, 175, 59, 0.726);
}

.btn--cancle:hover{
    background-color: rgba(187, 59, 59, 0.644);
}

.btn--approve:active{
    background-color: green;
}

.btn--cancle:active{
    background-color: red;
}



