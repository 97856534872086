.body-stakingPage{
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:150px;
    flex-direction: column;
}
.body-stakingPage2{
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:150px;
    flex-direction: column;
}
.note-title{
  color: #fff;
}
.img-content-stakingPage{
    position: absolute;
    display: flex;
    justify-content: flex-start;
    margin-top: -105px;
    margin-left: -9px;
    font-size: 8px;
    color: black;
    width: 100%;
}


@media only screen and (max-width: 768px) {
    .body-stakingPage2{
        min-height: 60vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 70px 30px 50px 30px;
    }
    .gemPurple-stakingPage{
        width: 33px !important;
        height: 33px !important;
        margin-top: 189px !important;
        margin-left: 84px !important;   
    }
    .img-stake{
        width: 200px !important;
        height: 344px !important;
    }
    .img-content-stakingPage{
        margin-bottom: -2px;
        margin-left: -9px;
    }
    .numberCard3-stakingPage{
        margin-bottom: 0px;
        margin-left: 0px;
    }
    .gem{
        width: 50px !important;
        height: 50px !important;
      }
}
.card2 {
    -webkit-transition: all 100ms ease-in-out;
    border-radius: 10;
    transition: all 100ms ease-in-out;
    
    -webkit-box-shadow: 0 1px 7px rgba(255, 255, 255, 0.05);
    box-shadow: 0 1px 7px rgba(255, 255, 255, 0.05);
  }
  .card2:hover {
    transform: scale(1.06);
    box-shadow: 0 4px 16px rgba(255, 255, 255);
    border-radius: 10px;

  }
  .shadow2{
    box-shadow:
      0 0 60px 30px #fff,  /* inner white */
      0 0 100px 60px #ff9900, /* middle #ffa31a */
      0 0 200px 100px #ffff00; /* outer #ffff1a */;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #fff;
      margin-right: 30px;
      /* position:absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%, -50%); */
  }