/*Card*/
/*Effect*/
.card0 {
    -webkit-transition: all 100ms ease-in-out;
  
    transition: all 100ms ease-in-out;
    border: none;
    -webkit-box-shadow: 0 1px 7px rgba(255, 255, 255, 0.05);
    box-shadow: 0 4px 16px rgba(255, 255, 255);
    border-radius:10px;
  }
  .card0:hover {
    transform: scale(1.06);
    box-shadow: 0 4px 16px rgba(255, 255, 255);
    border-radius:10px;
  }
  .card1{
    -webkit-transition: all 100ms ease-in-out;
    border-radius:10px;
    transition: all 100ms ease-in-out;
    border: none;
    -webkit-box-shadow: 0 1px 7px rgba(255, 255, 255, 0.05);
    box-shadow: 0 4px 16px #ff0000;
  }
  .card1:hover {
    transform: scale(1.06);
    box-shadow: 0 4px 16px #ff0000;
    border-radius:10px;
  }
  .card2{
    -webkit-transition: all 100ms ease-in-out;
    border-radius:10px;
    transition: all 100ms ease-in-out;
    border: none;
    -webkit-box-shadow: 0 1px 7px rgba(255, 255, 255, 0.05);
    box-shadow: 0 4px 16px #00ff00;
  }
  .card2:hover {
    transform: scale(1.06);
    box-shadow: 0 4px 16px #00ff00;
    border-radius:10px;
  }
  .card3 {
    -webkit-transition: all 100ms ease-in-out;
    border-radius:10px;
    transition: all 100ms ease-in-out;
    border: none;
    -webkit-box-shadow: 0 1px 7px rgba(255, 255, 255, 0.05);
    box-shadow: 0 4px 16px #ff9933;
  }
  .card3:hover {
    transform: scale(1.06);
    box-shadow: 0 4px 16px #ff9933;
    border-radius:10px;
  }
  .card4 {
    -webkit-transition: all 100ms ease-in-out;
    border-radius:10px;
    transition: all 100ms ease-in-out;
    border: none;
    -webkit-box-shadow: 0 1px 7px rgba(255, 255, 255, 0.05);
    box-shadow: 0 4px 16px #ffff00;
  }
  .card4:hover {
    transform: scale(1.06);
    box-shadow: 0 4px 16px #ffff00;
    border-radius:10px;
  }
  .card5{
    -webkit-transition: all 100ms ease-in-out;
    border-radius:10px;
    transition: all 100ms ease-in-out;
    border: none;
    -webkit-box-shadow: 0 1px 7px rgba(255, 255, 255, 0.05);
    box-shadow: 0 4px 16px #9900ff;
  }
  .card5:hover {
    transform: scale(1.06);
    box-shadow: 0 4px 16px #9900ff;
    border-radius:10px;
  }
  .card6{
    -webkit-transition: all 100ms ease-in-out;
    border-radius:10px;
    transition: all 100ms ease-in-out;
    border: none;
    -webkit-box-shadow: 0 1px 7px rgba(255, 255, 255, 0.05);
    box-shadow: 0 4px 16px #66ffff;
  }
  .card6:hover {
    transform: scale(1.06);
    box-shadow: 0 4px 16px #66ffff;
    border-radius:10px;
  }
  .card7 {
    -webkit-transition: all 100ms ease-in-out;
    border-radius:10px;
    transition: all 100ms ease-in-out;
    border: none;
    -webkit-box-shadow: 0 1px 7px rgba(255, 255, 255, 0.05);
    box-shadow: 0 4px 16px #0000ff;
  }
  .card7:hover {
    transform: scale(1.06);
    box-shadow: 0 4px 16px #0000ff;
    border-radius:10px;
  }
/*end*/
  .grid-container{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(200px,1fr));
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .mainCard{
    display: block;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    margin-bottom:32px;
  }