.button{
    display: inline-block;
    text-decoration: none;
    border: none;
    outline: none;
    padding: 12px 12px;
    border-radius: 6px;
    cursor: pointer;
    transition: opacity 0.2s ease;
    user-select: none;
    font-size: 2.5vmin;
    font-family:"Jersey M54";
    background-color: rgb(147, 62, 0);
    min-width: 93px;
    color: #fff;
}

.button:hover{
    opacity: 0.8;
}
.button:active{
    opacity: 1;
}

/* GreyButton */
.greyButton{
    border: none;
    background-color: transparent;
    text-align: center;
  }
  
.greyButton-img{
    width: 134px;
    height: 54px;
}
  
.greyButton-content{
    margin-top: -40px;
    font-family:"Jersey M54" ;
}

/* WoodButton */
.woodButton{
    border: none;
    background-color: transparent;
    text-align: center;
  }
  
.woodButton-img{
    width: 134px;
    height: 54px;
}
  
.woodButton-content{
    margin-top: -40px;
    font-family:"Jersey M54" ;
    color: #fff;
}