.modal-loadingPage{
  position: fixed; /* Stay in place */
  /* z-index: 1;  */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.582); /* Black w/ opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 88;
}
.loader {
  position: relative;
    border: 5px solid #e9d8d866;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 150px;
    height: 150px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .logo-loadingPage{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 120px;
  }