.broad{
	width: 280px;
	height: 210px;
    padding: 20px;
    color: black;
	background: #fff;
	border-radius: 6px;
    text-align: center;
	box-shadow: 4px 8px 12px 0 rgba(0,0,0,0.4);
	animation: show-modal .7s ease-in-out;
	animation: hide-modal .6s ease-in-out both;

}

.clear{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.iconClear{
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.errorImg{
    width: 50px;
    height: 45px;
}

.title{
    display: block;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin: 14px 0 5px 0;
}

.subTitle{
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
    margin: 0;
    padding: 0 30px;
}