.middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    max-width: 400px;
}
  
hr {
    margin: auto;
    width: 40%;
}