.rainbow {
  position: relative;
  width: 98.8%;
  max-width: 250px;
  /* background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
  #0000ff, #00ff00,#ffff00, #ff0000); */
  /* animation: steam 20s linear infinite; */
  border-radius: 10px;
  padding-left: 0.2px;
  padding-right: 2px;
  padding-top: 2px;
  z-index: 1;
}
.rainbow:hover {
  transform: scale(1.06);
  box-shadow: 0 4px 16px rgba(255, 255, 255);
  border-radius:10px;
}
  .rainbow:before, .rainbow:after {
    content: '';
    position: absolute;
    left: -1px;
    top: 1px;
    background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
    #0000ff, #00ff00,#ffff00, #ff0000);
    background-size: 300%;
    width: calc(100% + 0px);
    height: calc(100% + 0px);
    z-index: -1;
    animation: steam 20s linear infinite;
    border-radius: 10px;
  }
  
  @keyframes steam {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  
  .rainbow:after {
    filter: blur(50px);
  }