.board-dynamic{
    height: 400px;
    position: relative;
    color: "white";
}

.board-dynamic-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.board-dynamic-content{
    position: absolute;
    width: 100%;
    text-align: center;
    margin-top: -250px;
}
.board-dynamic-content-title{
    color:#fff;
    font-family:"Jersey M54"
}
.modal-award-content-bottom2{
    
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    margin-top: -100px;
  }