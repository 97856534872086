.fidget-gemPage {
  transform: translate(-50%, -50%);
  width: 240px;
  height: 240px;
  overflow: hidden;
  animation: rotate 8s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
}

  .gem{
    width: 100px;
    height: 100px;
  }

  @keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0) scale(2);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg) scale(2);
    }
  }
  
  .end-gemPage, .gap-gemPage {
    position: absolute;
    width: 50px;
    height: 50px;
    // border-radius: 50%;
    // background-color: tomato;
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%;
      // border: 2px solid #ccc;
      // border-radius: 50%;
      // background-color: #333;
      // content: '';
      text-align: center;
    }
  }
  .center-gemPage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index :2;
  }
  .end-gemPage {
    z-index: 2;
    &:nth-child(1) {
      // top
      top: 8px;
      left: 135px;
      transform: translate(-50%, 0);
    }
    &:nth-child(2) {
      // right
      bottom: 25px;
      right: 57px;
    }
    &:nth-child(3) {
      // left
      bottom: 19px;
      left: 41px;
    }
    &:nth-child(4) {
      // left
      top: 76px;
      left: 177px;
    }
    
  }

  .gap-gemPage {
    position: absolute;
    width: 50px;
    height: 50px;
    // border-radius: 50%;
    // background-color: tomato;
    z-index: 1;
    &:after {
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      // background-color: #333;
      // content: '';
    }
    &:nth-child(5) {
      // top left
      top: 63px;
      left: 13px;
      transform: rotate(-21deg);
      &:after {
        top: -15px;
        left: -15px;
      }
    }   
}

@media only screen and (max-width: 768px) {
  .end-gemPage {
    &:nth-child(1) {
      // top
      top: 21px !important;
      left: 55% !important;
    }
    &:nth-child(2) {
      // right
      bottom: 18px !important;
      right: 48px !important;
    }
    &:nth-child(3) {
      // left
      bottom: 30px !important;
      left: 32px !important;

    }
    &:nth-child(4) {
      // left
      top: 71px !important;
      left: 127px !important;
    }
    
  }
  .gap-gemPage {
    &:nth-child(5) {
      // top left
      top: 54px !important;
      left: 23px !important;

    }

    
  }
}
