.mainFooter{
    background-image: linear-gradient(#003C44,#014049);
    padding: 40px 120px 60px 120px;
    font-family: "Jersey M54";
    text-decoration: none;
}

.linkPage a{
    color: #ffeda0;
    font-size: 14px;
    text-decoration: none;
    line-height: 30px;
}

.logoImgFooter{
    height: 300px;
}

.logoFooter{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(4px);
  -webkit-filter: blur(4px);
}