.board-result{
    height: 500px;
    position: relative;
    color: "white";
}

.board-result-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.board-result-content{
    position: absolute;
    width: 100%;
    color: "white";
}

.board-result-content-top{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -370px;
    color: "white";
}

.board-result-content-subtitle{
    display: flex;
    justify-content:  space-evenly;
    width: 100%;
    color: "white";
}
.board-result-content-bottom{
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    color: "white";
}

.board-result-content-greyBtn{
    border: none;
    outline: none;
    background-color: transparent;
    text-align: center;
    height: 50px;
    color: "white";
}

.board-result-content-greyBtn-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    color: "white";
}

.board-result-content-greyBtn-content{
    font-family:"Jersey M54" ;
    margin-top: -37px;
    color: "white";
}
.title{
    color:#fff;
}
.board-result-content-title{
    color:#fff;
}
