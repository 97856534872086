.root {
    --size: 110vh;
    --s: calc(var(--size) / 6);
    --bor: calc(var(--size) / 30);
    --boxShadow: calc(var(--size) / 12);
  }
.body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
.body-buyPage{
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 70px 30px 50px 30px
}
.imgCard{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.circle{
    left: 50%;
    top:50%;
    transform:translate(-50%,-50%);
    width: var(--size);
    height: var(--size);    
    filter:url(#wavy) blur(1px);
    position: absolute;
}
.circle::before,
.circle::after{
    content: "";
    position: absolute;
    top: var(--s);
    left: var(--s);
    right: var(--s);
    bottom: var(--s);
    border-radius:50%;
    border:var(--bor) solid rgba(0, 153, 255,.4)
}
.circle::before{
    box-shadow: 0 0 var(--boxShadow) rgb(0, 48, 102), inset 0 0 var(--boxShadow) rgb(0, 48, 102);
    -webkit-box-reflect: below 10px
      linear-gradient(transparent, transparent, rgba(255, 255, 255,0));
    animation: move 5s linear infinite;
}
.circle:after {
    box-shadow: 0 0 calc(var(--bor)/2) rgb(0, 153, 255), inset 0 0 var(--bor) rgb(0, 153, 255);
  }
  
  @keyframes move {
    0% {
      box-shadow: 0 0 var(--boxShadow) rgb(0, 48, 102), inset 0 0 var(--boxShadow) rgb(0, 48, 102);
      filter: hue-rotate(0deg);
    }
    20% {
      box-shadow: 0 0 60px rgb(0, 48, 102), inset 0 0 60px rgb(0, 48, 102);
    }
  
    40% {
      box-shadow: 0 0 40px rgb(0, 48, 102), inset 0 0 40px rgb(0, 48, 102);
    }
    60% {
      box-shadow: 0 0 80px rgb(0, 48, 102), inset 0 0 80px rgb(0, 48, 102);
    }
    80% {
      box-shadow: 0 0 100px rgb(0, 48, 102), inset 0 0 100px rgb(0, 48, 102);
    }
    100% {
      box-shadow: 0 0 var(--boxShadow) rgb(0, 48, 102), inset 0 0 var(--boxShadow) rgb(0, 48, 102);
      filter: hue-rotate(0deg);
    }
  }
  
  svg {
    width: 0;
    height: 0;
  }

.image-bounce {
  width: 100%;
  height: auto;
  max-width: 250px; 
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}	
	40% {-webkit-transform: translateY(-30px);}
	60% {-webkit-transform: translateY(-15px);}
}
 
@-moz-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
	40% {-moz-transform: translateY(-30px);}
	60% {-moz-transform: translateY(-15px);}
}
 
@-o-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
	40% {-o-transform: translateY(-30px);}
	60% {-o-transform: translateY(-15px);}
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}

.img-content-buyPage{
  position: absolute;
  display: flex;
  justify-content: center;
  margin-left: -5px;
  margin-top: -135px;
  font-size: 11px;
  color: black;
  width: 100%;
}
.chestContent{
  width: 100%;
  color: #fff;
}
.chestContent-title{
  display: flex;
  justify-content: center;
}
.chestContent-subtitle{
  display: flex;
  justify-content: center;
  line-height: 5px;
}

@media only screen and (max-width: 768px) {
  .image-bounce {
    width: 200px !important;
  }
  .root {
    --size: 100vw !important;
    --s: calc(var(--size) / 6) !important;
    --bor: calc(var(--size) / 30) !important;
    --boxShadow: calc(var(--size) / 12) !important;
    margin-bottom: 111px;
  }
  .chest {
    width: 350px !important;
  }
  /* .img-random{
    width: 194px !important;
    height: 304px !important;
    margin-top: 19px !important;
  } */
  .img-content-buyPage{
    margin-top: 215px !important;
    margin-left: -15px !important;
  }
  .gemPurple-buyPage{
    width: 32px !important;
    height: 32px !important;
    margin-top: 183px !important;
    margin-left: 1px !important;
}
  .btn-active{
    flex-direction: row !important;
    gap: 0 10px;
  }
  .numberCard3-buyPage{
    margin-bottom: -85px !important;
    margin-left: -2px !important;
  }
  /* .gemPurple-couter-buyPage{
    margin-bottom: -9px;
    margin-left: -6px;
  } */
  .containCard2{
    margin-bottom: 87px;
  }
}

.btnBuy {

  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 0;

}

.btnBuy:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.btnBuy:active {
  color: #000
}

.btnBuy:active:after {
  background: transparent;
}

.btnBuy:hover:before {
  opacity: 1;
}

.btnBuy:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(17, 17, 17, 0.267);
  left: 0;
  top: 0;
  border-radius: 5px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}