.fidget-stakingPage {
  transform: translate(-50%, -50%);
  width: 240px;
  height: 240px;
  overflow: hidden;
  //border: 1px solid red;  
  animation: rotate 8s linear infinite;
  margin-top:294px;
  margin-bottom: 75px;
  margin-left: 269px;
}

.planet-stakingPage{
  position: absolute;
  margin-top: -349px;
  margin-right: 1215px;
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0) scale(2);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg) scale(2);
  }
}

.end-stakingPage, .gap-stakingPage {
  position: absolute;
  width: 50px;
  height: 50px;
  // border-radius: 50%;
  // background-color: tomato;
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    // border: 2px solid #ccc;
    // border-radius: 50%;
    // background-color: #333;
    // content: '';
    text-align: center;
  }
}
.center-stakingPage {
  position: absolute;
  top: 48%;
  left: 51%;
  transform: translate(-50%, -50%);
  z-index :2;
}
.end-stakingPage {
  z-index: 2;
  &:nth-child(1) {
    // top
    top: 5px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  &:nth-child(2) {
    // right
    bottom: 35px;
    right: 46px;
  }
  &:nth-child(3) {
    // left
    bottom: 34px;
    left: 45px;
  }
  &:nth-child(4) {
    // left
    top: 71px;
    left: 15px;
  }
}

.gap-stakingPage {
  position: absolute;
  width: 50px;
  height: 50px;
  // border-radius: 50%;
  // background-color: tomato;
  z-index: 1;
  &:after {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    // background-color: #333;
    // content: '';
  }
  &:nth-child(5) {
    // top left
    top: 69px;
    left: 170px;
    transform: rotate(-21deg);
    &:after {
      top: -15px;
      left: -15px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .fidget-stakingPage {
    width: 190px !important;
    height: 190px !important;
    margin-top: 203px !important;
    margin-bottom: 50px !important;
    margin-left: 179px !important;

  }
.planet-stakingPage{
  position: relative !important;
  margin-left: -90px !important;
  margin-top: 164px !important;
  margin-bottom: 218px !important;
  margin-right: 0px !important;
}
  .end-stakingPage, .gap-stakingPage{
    width: 40px !important;
    height: 40px !important;
  }

  .end-stakingPage {
    &:nth-child(1) {
      // top
      top: 24px !important;
      left: 50% !important;
    }
    &:nth-child(2) {
      // right
      bottom: 29px !important;
      right: 39px !important;
    }
    &:nth-child(3) {
      // left
      bottom: 25px !important;
      left: 46px !important;
    }
    &:nth-child(4) {
      // left
      top: 62px !important;
      left: 26px !important;
    }
  }

  .gap-stakingPage {
    &:nth-child(5) {
      // top left
    top: 59px !important;
    left: 126px !important;
    }
  }

  .center-stakingPage{
    top: 40% !important;
    left: 50% !important;
  }
}