.img-content-cardPage{
  position: absolute;
  display: flex;
  justify-content: flex-start;
  margin-top: -120px;
  margin-left: 34px;
  font-size: 10px;
  color: black;
  width: 100%;
}

.gemsList{
    /* --spacing: 10px; */
    --spacing: -40px;
    --columns: 4;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(-1 * var(--spacing));
    margin-bottom: 5px;
}

@media screen and (max-width: 1023px) {
    .gemsList {
      --columns: 2;
    }
    .gemList-img{
        height: 100px;
    }
  }
@media screen and (max-width: 767px) {
    .gemsList {
      --columns: 1;
        
    }
    .gemList-img{
        width: 50% !important;
        margin-left: 120px;
        margin-bottom: var(--spacing) !important;
    }
    .gemList2-img{
        width: 50% !important;
        margin-left: 40px;
        margin-bottom: var(--spacing) !important;
    }
    .gemList-content{
        margin-top: -107px !important;
    }
    .btnGem {
        margin-top: 40px !important;
    }
    .btn-Item{
        margin-left: -45px
    }
  }

.gemList{
    position: relative;
    /* width: calc(calc(100% / var(--columns)) - var(--spacing)); */
    margin-left: var(--spacing);
    overflow: hidden;
    /* margin-bottom: var(--spacing); */
    margin-bottom: 20px;
}
.margin{
    margin: 16px;
}
.gemList-img{
    display: block;
    height: 270px;
    width: 270px;
    object-fit: contain;
    flex-shrink: 0;
}
.gemList2-img{
    display: block;
    height: 200px;
    width: 200px;
    object-fit: contain;
    flex-shrink: 0;
}
.gemList-content{
    /* position: absolute; */
    margin-top: -90px;
    text-align: center;
    color:#fff;
}

.btnGem{
    border: none;
    background-color: transparent;
    margin-top: 30px;
}

.btnGem-img{
    width: 134px;
    height: 54px;
}

.btnGem-content{
    position: absolute;
    margin-top: -38px;
    margin-left: 54px;
    font-family:"Jersey M54" ;
    color:#fff
}

.gemList-content-title, .gemList-content-subtitle{
    font-size: 10px;
}

@media only screen and (max-width: 768px) {
  .gemPurple-stakingPage{
      width: 33px !important;
      height: 33px !important;
      margin-top: 189px !important;
      margin-left: 84px !important;   
  }
  .img-stake{
      width: 200px !important;
      height: 344px !important;
  }
  .img-content-stakingPage{
      margin-bottom: -2px;
      margin-left: -9px;
  }
  .numberCard3-stakingPage{
      margin-bottom: 0px;
      margin-left: 0px;
  }
  /* .gemPurple-couter-stakingPage{
      margin-bottom: 0px;
      margin-left: 0px;
  } */
}