.cover{
    background-image: url('../assets/cover.jpg'); 
    background-size: cover;
    overflow-x: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed
  }
  .ad-contain{
    background-image: url(https://d36mxiodymuqjm.cloudfront.net/website/ui_elements/market/bg_market-banner.jpg);
    min-height:40px;
  }
  @media only screen and (max-width: 600px) {
      .ad-contain{
        display: none
      }
  } 
  @media only screen and (max-width: 768px) {
      .ad-contain{
        display: none
      }
  }
  .market-tabs .market-tab{
    color: #718b93;
    padding:'6px 20px';
    font:normal normal bold 15px/18px;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    margin-left: 32px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .market-tabs .market-tab:hover{
    color:'#ffeda0';
    border-bottom: 2px solid #ffeda0;
  }
  .market-tabs{
    flex-direction: row;
    display: flex;
    background-color: #000;
  }
  .market-tab:hover{
    color: white;
  }


  .circle-container {
    position: relative;
    width: 24em;
    height: 24em;
    /*2.8em = 2em*1.4 (2em = half the width of a link with img, 1.4 = sqrt(2))*/
    border-radius: 50%;
  
}
.circle-container a {
    display: block;
    position: absolute;
    top: 50%; left: 50%;
    width: 4em; height: 4em;
    margin: -2em;
}
.circle-container img { display: block; width: 100%; }
.deg0 { transform: translate(12em); } /* 12em = half the width of the wrapper */
.deg45 { transform: rotate(45deg) translate(12em) rotate(-45deg); }
.deg135 { transform: rotate(135deg) translate(12em) rotate(-135deg); }
.deg180 { transform: translate(-12em); }
.deg225 { transform: rotate(225deg) translate(12em) rotate(-225deg); }
.deg315 { transform: rotate(315deg) translate(12em) rotate(-315deg); }


ul.fireflies{list-style:none}
.fly{
	background-size: cover;
	background-image: url(http://i.imgur.com/aa7Rw.png);
	width:21px; 
	top:-20px;
	height:21px;
	position:absolute;
	animation: 
		leftright 24s infinite cubic-bezier(.39,.0,.63,1),
		updown 8s infinite 1.25s cubic-bezier(.39,.0,.63,1)
}
.fly:nth-of-type(2){
animation-delay:4s;
animation-duration:17s, 8s;
	animation-fill-mode:backwards, backwards 
}
.fly:nth-of-type(3){
animation-delay:1.5s;
animation-duration:14s, 7s;
animation-fill-mode:backwards, backwards
}
.fly:nth-of-type(4){
animation-delay:5.5s;
animation-duration:10s, 13s;
}
.fly:nth-of-type(5){
animation-fill-mode:backwards, backwards;
animation-duration:12s, 15s;
}

@keyframes leftright {
  0%, 100%{left:20%;}
	16.666%	{left:90%;}
	33.333% {left:10%;}
	50%			{left:50%;}
  66.666%	{left:70%;}
	83.333% {left:40%;}
}

@keyframes updown {
  0%, 100%{top:0px;width:20px;height:20px}
  25%{top:70%; }
	50%{top:50%;width:30px;height:30px}
	75%{top:70%;width:10px;height:10px }
}

.btn04{
  border: none;
  outline: none;
  background-color: transparent;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn04:active {
  transform: translateY(4px);
}

.btn04-img{
  width: 80px;
  height: 80px;
}

.btn04-content{
  position: absolute;
  font-family:"Jersey M54" ;
}
