.shadow1{
    box-shadow:
      0 0 60px 30px #fff,  /* inner white */
      0 0 100px 60px #ff9900, /* middle #ffa31a */
      0 0 200px 100px #ffff00; /* outer #ffff1a */;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #fff;
      margin-right: 30px;
      /* position:absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%, -50%); */
  }
.body-Gem{
  background-color: rgba(0, 0, 0, 0.5);
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px black solid;
  margin-bottom: 50px;
}

.imgGem{
  max-width: 100px;
}

@media screen and (max-width: 767px) {
  .body-Gem{
    width: 50%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 20px;
  }
}

