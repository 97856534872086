.body-pvePage{
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:150px;
    flex-direction: "column";
  }
  .body-Gem{
    min-height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .container-spinner{
    position: relative;
  }
  
  .btn-planet{
    display: inline-block;
      text-decoration: none;
      border: none;
      outline: none;
      padding: 12px 12px;
      border-radius: 6px;
      cursor: pointer;
      transition: opacity 0.2s ease;
      user-select: none;
      font-size: 2.5vmin;
      font-family:"Jersey M54";
      background-color: rgb(147, 62, 0);
      min-width: 93px;
      color: #fff;
  }
  
  .planet-pvePage{
    margin-top: -343px;
    margin-right: 281px;
  }
  
  .btnResult{
    display: flex;
    flex-direction: column;
    margin-top: 207px;
  }
  
  .fidget-pvePage {
      transform: translate(-50%, -50%);
      width: 240px;
      height: 240px;
      // overflow: hidden;
      animation: rotate 8s linear infinite;
      // margin: auto;
      // margin-top: 270px;
      // margin-right: 69px;
      // margin-left: 324px;
      position: absolute;
    }
    @keyframes rotate {
      from {
        transform: translate(-50%, -50%) rotate(0) scale(2);
      }
      to {
        transform: translate(-50%, -50%) rotate(360deg) scale(2);
      }
    }
    
    .end-pvePage, .gap-pvePage {
      position: absolute;
      width: 50px;
      height: 50px;
      // border-radius: 50%;
      // background-color: tomato;
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        height: 50%;
        // border: 2px solid #ccc;
        // border-radius: 50%;
        // background-color: #333;
        // content: '';
        text-align: center;
      }
    }
    .center-pvePage {
      position: absolute;
      top: 50%;
      left: 32%;
      transform: translate(-50%, -50%);
      z-index :2;
    }
    .end-pvePage {
      z-index: 2;
      &:nth-child(1) {
        // top
        top: 5px;
        left: 50%;
        transform: translate(-50%, 0);
      }
      &:nth-child(2) {
        // right
        bottom: 29px;
        right: 39px;
      }
      &:nth-child(3) {
        // left
        bottom: 25px;
        left: 46px;
      }
      &:nth-child(4) {
        // left
        top: 85px;
        left: 181px;
      }
    }
  
    .gap-pvePage {
      position: absolute;
      width: 50px;
      height: 50px;
      // border-radius: 50%;
      // background-color: tomato;
      z-index: 1;
      &:after {
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        // background-color: #333;
        // content: '';
      }
      &:nth-child(5) {
        // top left
        top: 64px;
          right: 21px;
        transform: rotate(-21deg);
        &:after {
          top: -15px;
          left: -15px;
        }
      }
  
    }
  
  .img-content-pvePage{
    position: absolute;
    display: flex;
    justify-content: flex-start;
    margin-top: -120px;
    margin-left: 34px;
    font-size: 10px;
    color: black;
    width: 100%;
  }
  
    @media only screen and (max-width: 768px) {
      .body-pvePage{
        min-height: 100vh;
        display: flex !important;
        align-items: center !important;
        flex-direction: column !important;
        padding: 70px 30px 50px 30px;
      }
      .planet-pvePage{
        position: relative !important;
        margin-left: -90px !important;
        margin-top: 164px !important;
        margin-bottom: 218px !important;
        margin-right: 0px !important;
      }
      .container-spinner{
        margin-top: 174px;
        margin-bottom: 200px;
      }
      .fidget-pvePage {
        width: 190px !important;
        height: 190px !important;
        // margin-top: 205px !important;
        // margin-bottom: 50px !important;
        // margin-left: 274px !important;
      }
      .end-pvePage, .gap-pvePage{
        width: 40px !important;
        height: 40px !important;
      }
  
      .end-pvePage {
        &:nth-child(1) {
          // top
          top: 24px !important;
          left: 50% !important;
        }
        &:nth-child(2) {
          // right
          bottom: 29px !important;
          right: 39px !important;
        }
        &:nth-child(3) {
          // left
          bottom: 25px !important;
          left: 46px !important;
        }
        &:nth-child(4) {
          // left
          top: 62px !important;
          left: 26px !important;
        }
      }
  
      .center-pvePage{
        top: 40% !important;
        left: 50% !important;
      }
      .iconStat{
        margin-left: -492px;
        margin-bottom: -392px ;
        width: 160px !important;
      }
      .iconStat1{
        margin-left: -501px ;
        margin-bottom: -391px ;
        width: 160px !important;
      }
      .numberCard3-pvePage-left{
        margin-bottom: -23px !important;
        margin-left: 1px !important; 
      }
      .numberCard3-pvePage-right{
        margin-left: 1px !important;
        margin-bottom: -22px !important;
      }
      .gemPurple-pvePage{
        margin-bottom: -10px;
        margin-left: -3px;
      }
      // .gemPurple-couter-pvePage-left{
      //   margin-bottom: -8px;
      //   margin-left: -5px;
      // }
      // .gemPurple-couter-pvePage-right{
      //   margin-bottom: -8px;
      //   margin-left: -3px;
      // }
      .img-content-pvePage{
        margin-top: 200px;
        margin-left: -15px;
      }
      .img-content-pvePage2{
        margin-top: 206px;
        margin-left: -15px;
      }
      .emptyImg{
        width: 98% !important;
        height: 323px !important;
      }
      .bossEmpty{
        width: 170px !important;
        height: 253px !important;
      }
      .columnBtn{
        height: 248px !important;
      }
    }