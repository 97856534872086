.board-login{
    height: 500px;
    position: relative;
}

.board-login-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.board-login-content{
    position: absolute;
    width: 100%;
    margin-top: -340px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.board-login-infor{
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px 0;
}

.board-login-inforBNB-content, .board-login-inforLogo-content{
    line-height: 5px;
}

.board-login-inforBNB, .board-login-inforLogo{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 300px;
}

.board-login-inforLogo{
    margin-left: 20px;
}

.bnbImg{
    width: 75px;
    height: 60px;
}

.board-loginBNB-title, .board-loginLogo-title{
    font-size: 15px;
    color: rgba(128, 128, 128, 0.411);
}

.board-loginBNB-subtitle, .board-loginLogo-subtitle{
    font-size: 20px;
}

.logoImg{
    width: 56px;
    height: 60px;
}

.board-login-button{
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    width: 450px;
}

.board-login-buttonBuy, .board-login-buttonLogout{
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
}

.board-login-buttonBuy-img, .board-login-buttonLogout-img{
    width: 100px;
    height: 50px;
}

