.btn-spinner{
    display: inline-block;
    text-decoration: none;
    border: none;
    outline: none;
    padding: 12px 12px;
    border-radius: 6px;
    cursor: pointer;
    transition: opacity 0.2s ease;
    user-select: none;
    font-size: 2.5vmin;
    font-family:"Jersey M54";
    background-color: rgb(147, 62, 0);
    min-width: 93px;
    color: #fff;
}