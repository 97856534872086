.img-content-popup{
    position: absolute;
    display: flex;
    justify-content: flex-start;
    margin-top: -107px;
    margin-left: 38px;
    font-size: 9px;
    color: black;
    width: 100%;
    font-family: "Jersey M54";
}
.center{
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}
.img-content-popup2{
    position: absolute;
    display: flex;
    justify-content: flex-start;
    margin-top: -140px;
    margin-left: 35px;
    font-size: 12px;
    color: black;
    width: 100%;
    font-family: "Jersey M54";
}
.gemsList{
    /* --spacing: 10px; */
    --spacing: -40px;
    --columns: 4;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(-1 * var(--spacing));
    margin-bottom: 5px;
}

@media screen and (max-width: 1023px) {
    .gemsList {
      --columns: 2;
    }
    .gemList-img{
        height: 100px;
    }
  }
@media screen and (max-width: 767px) {
    .gemsList {
      --columns: 1;
        
    }
    .gemList-img{
        width: 50% !important;
        margin-left: -5px;
        margin-bottom: var(--spacing) !important;
    }
    .gemList-content{
        margin-top: -107px !important;
    }
    .btnGem {
        margin-top: 40px !important;
    }
  }

.gemList{
    position: relative;
    /* width: calc(calc(100% / var(--columns)) - var(--spacing)); */
    margin-left: var(--spacing);
    overflow: hidden;
    /* margin-bottom: var(--spacing); */
    margin-bottom: 20px;
}

.gemList-img{
    display: block;
    height: 300px;
    width: 300px;
    object-fit: contain;
    flex-shrink: 0;
}

.gemList-content{
    /* position: absolute; */
    margin-top: -90px;
    text-align: center;
    color:#fff;
}

.btnGem{
    border: none;
    background-color: transparent;
    margin-top: 30px;
}

.btnGem-img{
    width: 134px;
    height: 54px;
}

.btnGem-content{
    position: absolute;
    margin-top: -38px;
    margin-left: 54px;
    font-family:"Jersey M54" ;
    color:#fff
}

.gemList-content-title, .gemList-content-subtitle{
    font-size: 10px;
}

@media only screen and (max-width: 768px) {
  .gemPurple-stakingPage{
      width: 33px !important;
      height: 33px !important;
      margin-top: 191px !important;
      margin-left: 88px !important;   
  }
  .img-stake{
      width: 200px !important;
      height: 344px !important;
  }
  .img-content-stakingPage{
      margin-bottom: -2px;
      margin-left: -9px;
  }
  .numberCard3-stakingPage{
      margin-bottom: 0px;
      margin-left: 0px;
  }
  /* .gemPurple-couter-stakingPage{
      margin-bottom: 0px;
      margin-left: 0px;
  } */
}