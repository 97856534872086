.root-gemPage {
    --size: 100vh;
    --s: calc(var(--size) / 6);
    --bor: calc(var(--size) / 30);
    --boxShadow: calc(var(--size) / 12);
}
.body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
.body-gemPage{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding: 70px 30px 50px 30px;
}

.containSpinner{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.circle2{
  left: 50%;
  top:50%;
  transform:translate(-50%,-50%);
  width: var(--size);
  height: var(--size);    
  filter:url(#wavy) blur(1px);
  position: absolute;
}
.circle2:before,
.circle2:after {
  content: "";
  position: absolute;
  top: var(--s);
  left: var(--s);
  right: var(--s);
  bottom: var(--s);
  border-radius: 50%;
  border: var(--bor) solid #fff;
}
.circle2:before {
  box-shadow: 0 0 var(--boxShadow) #0f0, inset 0 0 var(--boxShadow) #0f0;
  -webkit-box-reflect: below 10px
    linear-gradient(transparent, transparent, #0002);
  animation: move 5s linear infinite;
}

.circle2:after {
  box-shadow: 0 0 calc(var(--bor)/2) #fff, inset 0 0 var(--bor) #fff;
}

@keyframes move {
  0% {
    box-shadow: 0 0 var(--boxShadow) #0f0, inset 0 0 var(--boxShadow) #0f0;
    filter: hue-rotate(0deg);
  }
  20% {
    box-shadow: 0 0 60px #0f0, inset 0 0 60px #0f0;
  }

  40% {
    box-shadow: 0 0 40px #0f0, inset 0 0 40px #0f0;
  }
  60% {
    box-shadow: 0 0 80px #0f0, inset 0 0 80px #0f0;
  }
  80% {
    box-shadow: 0 0 100px #0f0, inset 0 0 100px #0f0;
  }
  100% {
    box-shadow: 0 0 var(--boxShadow) #0f0, inset 0 0 var(--boxShadow) #0f0;
    filter: hue-rotate(360deg);
  }
}

svg {
    width: 0;
    height: 0;
}

.btn-gem {
    min-width: 100px;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 2.5vmin;
    background: linear-gradient(to top,#6a1111,#711414,#801a1a,#a72929,#cd3838,#fa4a4a);
    
    transition: all 0.2s ease-in-out;
    border-radius: 6px;

}

.greyBtn{
  border: none;
  background-color: transparent;
  position: relative;
}

.greyBtn-img{
  width: 134px;
  height: 54px;
}

.greyBtn-content{
  position: absolute;
  top: 18px;
  right: 52px;
  font-family:"Jersey M54" ;
}
.greyBtn-content2{
  position: absolute;
  top: 18px;
  right: 60px;
  font-family:"Jersey M54" ;
  color:#fff;
}

@media only screen and (max-width: 768px) {
    .root-gemPage {
        --size: 100vw !important;
        --s: calc(var(--size) / 6) !important;
        --bor: calc(var(--size) / 30) !important;
        --boxShadow: calc(var(--size) / 12) !important;
        margin-bottom: 111px;
    }
    .end-gemPage, .gap-gemPage{
      width: 40px !important;
      height: 40px !important;
    }
    .fidget-gemPage {
      width: 190px !important;
      height: 190px !important;
      margin-top: 266px !important;
      margin-left: 193px !important;
    }
    .gem{
      width: 50px !important;
      height: 50px !important;
    }
    .greyBtn-img{
      width: 91px !important;
      height: 41px !important;
    }
    .greyBtn-content{
      top: 13px !important;
      right: 31px !important;
    }
}

.image-bounce2 {
  /* max-width: 200px;
  height: auto; */
  animation: bounce 1s infinite;
  -webkit-animation: bounce 1s infinite;
  -moz-animation: bounce 1s infinite;
  -o-animation: bounce 1s infinite;
}

@-webkit-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}	
	40% {-webkit-transform: translateY(-30px);}
	60% {-webkit-transform: translateY(-15px);}
}
 
@-moz-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
	40% {-moz-transform: translateY(-30px);}
	60% {-moz-transform: translateY(-15px);}
}
 
@-o-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
	40% {-o-transform: translateY(-30px);}
	60% {-o-transform: translateY(-10px);}
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}

/* .image-bounce {
  max-width: 200px;
  height: auto;
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
} */

@-webkit-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}	
	40% {-webkit-transform: translateY(-30px);}
	60% {-webkit-transform: translateY(-15px);}
}
 
@-moz-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
	40% {-moz-transform: translateY(-30px);}
	60% {-moz-transform: translateY(-15px);}
}
 
@-o-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
	40% {-o-transform: translateY(-30px);}
	60% {-o-transform: translateY(-15px);}
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}
.shadow{
  box-shadow:
    0 0 60px 30px #fff,  /* inner white */
    0 0 100px 60px #ff9900, /* middle #ffa31a */
    0 0 200px 100px #ffff00; /* outer #ffff1a */;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    position:absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
}